/* style.css */


/***************************************/
/* Performance                         */
/***************************************/

#embed-performance {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#performance {
  background-color: #000000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#performance > div {
  overflow: auto;
  border-style: none;
  border-color: #666666;
}

#performance > div > div.panel-default {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #666666;
  border-color: #000000;
  margin: 0;
}

#performance > div > div.panel-default > div.panel-heading {
  flex: 0;
  border-color: #000000;
  background-color: #333333;
}

#performance > div > div.panel-default > div.panel-heading > h4.panel-title {
  color: #dddddd;
  text-align: center;
  font-size: 200%;
}

#performance > div > div.panel-default > div.panel-body {
  flex: 1;
  overflow: auto;
}

#performance > div > div.panel-default > div.panel-body > div {
  height: 100%;
  text-align: center;
}

#performance > div > div.panel-default > div.panel-body > div > * {
  margin: 0 auto !important;
}

#performance-tl {
  position: absolute;
  top: 0;
  right: 50%;
  bottom: 50%;
  left: 0;
  padding: 15px 7.5px 7.5px 15px;
  border-width: 0 1px 1px 0;
}

#performance-tr {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50%;
  left: 50%;
  padding: 15px 15px 7.5px 7.5px;
  border-width: 0 0 1px 1px;
}

#performance-bl {
  position: absolute;
  top: 50%;
  right: 50%;
  bottom: 0;
  left: 0;
  padding: 7.5px 7.5px 15px 15px;
  border-width: 1px 1px 0 0;
}

#performance-br {
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 0;
  left: 50%;
  padding: 7.5px 15px 15px 7.5px;
  border-width: 1px 0 0 1px;
}

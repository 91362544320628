#embed-performance {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#performance {
  background-color: #000;
  position: absolute;
  inset: 0;
}

#performance > div {
  border-style: none;
  border-color: #666;
  overflow: auto;
}

#performance > div > div.panel-default {
  background-color: #666;
  border-color: #000;
  flex-direction: column;
  height: 100%;
  margin: 0;
  display: flex;
}

#performance > div > div.panel-default > div.panel-heading {
  background-color: #333;
  border-color: #000;
  flex: 0;
}

#performance > div > div.panel-default > div.panel-heading > h4.panel-title {
  color: #ddd;
  text-align: center;
  font-size: 200%;
}

#performance > div > div.panel-default > div.panel-body {
  flex: 1;
  overflow: auto;
}

#performance > div > div.panel-default > div.panel-body > div {
  text-align: center;
  height: 100%;
}

#performance > div > div.panel-default > div.panel-body > div > * {
  margin: 0 auto !important;
}

#performance-tl {
  border-width: 0 1px 1px 0;
  padding: 15px 7.5px 7.5px 15px;
  position: absolute;
  inset: 0 50% 50% 0;
}

#performance-tr {
  border-width: 0 0 1px 1px;
  padding: 15px 15px 7.5px 7.5px;
  position: absolute;
  inset: 0 0 50% 50%;
}

#performance-bl {
  border-width: 1px 1px 0 0;
  padding: 7.5px 7.5px 15px 15px;
  position: absolute;
  inset: 50% 50% 0 0;
}

#performance-br {
  border-width: 1px 0 0 1px;
  padding: 7.5px 15px 15px 7.5px;
  position: absolute;
  inset: 50% 0 0 50%;
}
/*# sourceMappingURL=index.a035a4b8.css.map */
